<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
    </v-toolbar>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :rows-per-page-items="[10, 20, 50]"
      :search="search"
      item-key="entry"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealerId }}
          </td>
          <td class="text-xs-left">
            {{ props.item.rank }}
          </td>
          <td class="text-xs-right">
            {{ props.item.incentive }}
          </td>
        </tr>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-right info">
            RM {{ totalIncentive }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'

const title = 'Dealer Appointment'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: title, disabled: true,
        },
      ],
      date: null,
      headers: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Dealer Id',
          align: 'left',
          sortable: true,
          value: 'dealerId',
        },
        {
          text: 'Rank',
          align: 'left',
          sortable: true,
          value: 'rank',
        },
        {
          text: 'Incentive (RM)',
          align: 'right',
          sortable: true,
          value: 'incentive',
        },
      ],
      items: [],
      loading: false,
      search: '',
      title: title,
      totalIncentive: 0.00,
    }
  },
  mounted: function () {
    this.getDealerRecruitmentIncentive()
  },
  methods: {
    getDealerRecruitmentIncentive: function (date) {
      this.loading = true
      this.alert = false
      this.items = []
      this.totalIncentive = '0.00'
      this.$rest.get('getDealerRecruitmentIncentive.php', createGetParams())
        .then(function (response) {
          this.items = response.data.item
          this.totalIncentive = response.data.totalIncentive
          if (response.data.message) {
            this.alertMessage = response.data.message
            this.alertType = 'info'
            this.alert = true
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alertType = 'error'
          this.alert = true
          this.loading = false
        })
    },
  },
}
</script>
